/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const HaltinVaellus = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vaellukset/haltin-vaellus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Haltin Vaellus',
    description:
      'Lähde kanssamme valloittamaan Käsivarren erämaahan Suomen korkein kohta Halti. Tämä upea ja uniikki vaellus pitää sisällään vesitasolla lentämisen erämaahan, josta vaellus alkaa. Haltin vaellus on niin upea kokemus, että kylmät väreet vipeltävät pitkin kehoasi, vatsanpohjaa kutkuttaa ja sydän meinaa pakahtua. Ja mikä parasta, irtaudut ulkomaailmasta vaelluksen ajaksi, sillä puhelimissa ei ole kuuluvuutta kuin vasta lähempänä vaelluksen loppua.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="haltin-vaellus"
    >
      <div className="camp-text-content">
        <h2>Haltin vaellus</h2>
        <h3>31.7.-3.8.2025</h3>
        <p className="bolded">
          Lähde kanssamme valloittamaan Käsivarren erämaahan Suomen korkein
          kohta Halti. Tämä upea ja uniikki vaellus pitää sisällään vesitasolla
          lentämisen erämaahan, josta vaellus alkaa. Haltin vaellus on niin upea
          kokemus, että kylmät väreet vipeltävät pitkin kehoasi, vatsanpohjaa
          kutkuttaa ja sydän meinaa pakahtua. Ja mikä parasta, irtaudut
          ulkomaailmasta vaelluksen ajaksi, sillä puhelimissa ei ole kuuluvuutta
          kuin vasta lähempänä vaelluksen loppua.
        </p>
        <br />
        <p>
          Vaellusreitti on uskomattoman upea ja matkan varrella on paljon eri
          luonnonnähtävyyksiä, joita jäämme ihailemaan: Jännittäviä
          vesiputouksia, pyhiä järviä sekä tuntureita, tunturierämaan eläimiä ja
          kasveja unohtamatta. Reitti itsessään koostuu monesta puron ja jokien
          ylityksistä, kivikkoisesta sekä louhikkoista reitistä, matkalla on
          myös vähän helpohkoa polkua sekä nousua ja laskua. Suurin nousu on
          noin 1324 metrin korkeudessa olevalle Suomen-puoleiselle Haltin
          huipulle heti ensimmäisenä päivänä sään salliessa.
        </p>
      </div>
      {/* <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg1.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div> */}
      <GatsbyImage
        image={data.campImg6.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Vaelluksen aikataulu ja vaativuus:</p>
        <p>
          Vaellus kestää neljä päivää ja kolme yötä, jotka nukumme omissa
          teltoissamme. Lähdemme vesitasolla matkaan Kilpisjärveltä
          torstaiaamuna 31.7.2025. Vesitasosta käsin ihailemme Käsivarren
          erämaan upeutta ja tämä kokemus jää varmasti ikuisesti mieleesi.
          Vaellus päättyy Kilpisjärvelle, jonne saavumme sunnuntaina
          iltapäivällä. Vaelluksen päivämatkat ovat noin 18 km, 12 km, 19 km,
          12km ja koko reitin pituudeksi tulee noin 57km.
          <br />
          <br />
          Tämä vaellus sopii hyväkuntoisille ja jo hieman vaelluskokemusta
          omaaville naisille. Vaellukselle otetaan enintään viisi naista,
          minimäärä vaelluksen toteutumiseen on kaksi.
        </p>
        <br />
        <p className="bolded">Vaelluksen opas</p>
        <p>
          Oppaana vaelluksella toimii eräopas Sanni Lampinen. Muoniossa asuva
          Sanni on toiselta ammatiltaan fysioterapeutti ja hän toimii oppaana
          myös Discover Laplandin hiihtovaelluksilla. Sanni on kiinnostunut
          ihmisen kokonaisvaltaisesta hyvinvoinnista ja siitä, miten voimme
          luonnossa eheyttää itseämme. Retkeilijänä Sanni on nautiskelija ja
          seikkailija. Hän nauttii ympäröivästä luonnosta, sen kauniista
          yksityiskohdista, rauhasta, eri sääolosuhteista ja kaikesta
          erilaisuudesta mitä luonto meille tarjoaa.
        </p>
        <br />
        <p className="bolded">Ennen retkeä</p>
        <p>
          Lähetämme sinulle tarkan pakkauslistan, retkisuunnitelman,
          turvallisuussuunitelman sekä riskianalyysin, jotka käymme läpi
          retkelle lähtijöiden kanssa yhteisissä online-tapaamisessa. Retkellä
          on myös oma WA-ryhmä, joka toimii meidän tiedotuskanavana ja jossa
          osallistujat voivat kysellä mm. kimppakyytejä / kimppamajoituksia
          ennen/jälkeen vaelluksen.
        </p>
        <br />
        <p className="bolded">Mitä tarvitset mukaasi vaellukselle?</p>
        <p>
          - Vaellukseen sekä eri säihin sopivat vaatteet ja vaelluskengät <br />
          -- Teltta, makuualustat sekä makuupussi <br /> - Rinkka <br /> -
          Ruuantekovälineet, ruokaa, vesipullo / termospullo <br />-
          Kävely/vaellussauvat
        </p>
      </div>
      <GatsbyImage
        image={data.campImg2.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <br />
        <p className="bolded">Vaelluksen hinta on 750€ (sis alv.)</p>
        <br />
        <p>
          Hintaan kuuluu: <br /> Pakkauslista, päiväkohtainen retkisuunnitelma,
          turvallisuussuunnitelma sekä riskianalyysi Opas neljäksi päiväksi{' '}
          <br /> Ennen retkeä: Osallistujien kanssa retkelle valmistautuminen
          verkkotapaamisessa (1.7.2025 klo 19-21.30), jossa käymme läpi retken
          materiaalit sekä tutustumme toisiimme.
        </p>
        <br />
        <p>
          <span className="bolded">
            Ilmoittaudu mukaan sähköpostitse{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>{' '}
            ja toimi näin:
          </span>
          <br />
          OTSIKKO: Haltin vaellus 2025 <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi vaelluskokemuksesi? <br />
          Mikä on kuntotasosi eli kuinka paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?{' '}
          <br />
          <br />
          Sinulle tulee itsellesi kustannettavaksi omat kuljetukset
          Kilpisjärvelle, yöpymiset Kilpisjärvellä ennen retkeä tai retken
          jälkeen. <br />
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg4.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg5.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p>
          <span className="bolded">Retken varaus- ja peruutusehdot:</span>{' '}
          <br />
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 250€. Kun lasku on maksettu, saat paluupostina
          materiaalit; pakkauslistan ja retkisuunnitelman sekä online-tapaamisen
          linkin.
        </p>
        <br />
        <p>
          Vaelluksen loppulaskun 500€ saat sähköpostiisi vaelluksen
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
        </p>
        <br />
        <p>
          Jos perut osallistumisesi vaellukselle missä kohtaa tahansa, pidätämme
          toimitetut vaelluksen materiaalien kulut sekä toimistokulut 250€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          <br />
          <br />
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus vaelluksen aikana.
          <br />
          <br />
          Tekisikö mieli mukaan tälle ainutlaatuiselle vaellukselle? Mikäli
          sinulla on kysyttävää vaelluksesta, voit olla yhteydessä Anetteen joko
          meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com{' '}
          </a>
          tai soittamalla 040 772 4124.
          <br />
          <br />
          Tervetuloa mukaan tekemään unohtumaton Haltin ja Käsivarren erämaan
          naisten vaellus!
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(relativePath: { eq: "images/hikes/Hike-Halti-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(relativePath: { eq: "images/hikes/halti-2023-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(relativePath: { eq: "images/hikes/halti-2020-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(relativePath: { eq: "images/hikes/halti-2023-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(relativePath: { eq: "images/hikes/halti-2023-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg5: file(relativePath: { eq: "images/hikes/halti-2023-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg6: file(relativePath: { eq: "images/hikes/haltibyplane-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(relativePath: { eq: "images/hikes/Hike-Halti-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default HaltinVaellus;
